import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTitle from '../hooks/title.hook'

export const NewsPage = () => {
   useTitle('Новости - InterBoard')
   return (
      <Box sx={{ width: '100%', maxWidth: 1000 }}>
         <Typography variant="h1" gutterBottom>
            Новости
         </Typography>
         <Typography variant="h3" gutterBottom>
            На этой странице будет располагаться лента новостей сайта.
            Новости об обновлении и всякие акции возможно.
            Не уверен, что она нужна.
         </Typography>
         
      </Box>
   )
}
