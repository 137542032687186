import axios from 'axios'
import Cookies from 'js-cookie'
export const API_URL = process.env.REACT_APP_API_URL

const $api = axios.create({
   withCredentials: true,
   baseURL: API_URL,
})

$api.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`
   return config
})

$api.interceptors.response.use(
   (config) => {
      return config
   },
   async (error) => {
      const originalRequest = error.config
      if (error.response.status === 401 && error.config && !error.config._isRetry) {
         originalRequest._isRetry = true
         try {
            var response
            if (Cookies.get('accessToken')) {
               response = await axios.get(`${API_URL}/api/auth/refresh`, { withCredentials: true }) //Аксес токен не провалидировался в auth-middleware
               if (response.data.token) {
                  Cookies.set('accessToken', response.data.token)
                  return $api.request(originalRequest)
               } else {
                  window.location.href = process.env.REACT_APP_API_URLREACT
                  $api.post(`${API_URL}/api/auth/logout`, { withCredentials: true }) //Это похоже не работает и токены не удаляются
                  Cookies.remove('userId')
                  Cookies.remove('username')
               }
            }
         } catch (e) {
            console.log('Не авторизован')
            window.location.href = process.env.REACT_APP_API_URLREACT
         }
      }

      throw error
   }
)

export default $api
