import React, { useState, useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import { useMessage } from '../hooks/message.hook'
import { Suspense } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useTitle from '../hooks/title.hook'

import { AuthContext } from '../context/AuthContext'
import BoardCard from '../components/BoardCard.js'
import $api from '../http/index.js'

export default function MainPage() {
   useTitle('Главная страница - InterBoard')
   const message = useMessage()
   const auth = useContext(AuthContext) //Контекст

   const [listOfBoards, setListOfBoards] = useState([]) //Это для отображения списка досок
   const [open, setOpen] = useState(false) //Открытие и закрытие диалога
   const [name, setName] = useState('') //Для считывания названия доски из диалогового окна
   const [currentTime, setCurrentTime] = useState(new Date())
   const handleChange = (e) => setName(e.target.value)

   useEffect(() => {
      //Для начального рендера списка досок
      const asyncFn = async () => {
         const data = (
            await $api.post('/api/manager/render', {
               id: auth.userId,
            })
         ).data
         setListOfBoards(data.myboards)
      }
      asyncFn()
   }, [auth.userId])
   useEffect(() => {
      const timerID = setInterval(() => setCurrentTime(new Date()), 1000)
      return () => clearInterval(timerID) // Очистка таймера при размонтировании компонента
   }, [])

   const handleClickOpen = () => {
      setOpen(true)
   }
   const handleClose = () => {
      setOpen(false)
   }

   const handleCreate = async () => {
      //Создание доски
      let boardName = name
      if (!boardName) boardName = 'Доска от ' + currentTime.toLocaleDateString() + ', ' + currentTime.toLocaleTimeString()
      if (listOfBoards.length >= 30) {
         message('Достигнуто максимальное количество досок!')
         return
      }
      let isUniq = true
      listOfBoards.forEach((el) => {
         //Проверка на уникальность названия новой доски
         if (el.name === boardName) isUniq = false
      })
      if (!isUniq) {
         message('Доска с таким именем уже существует!')
         return
      }
      const data = (
         await $api.post('/api/manager/create', {
            id: auth.userId,
            name: boardName,
         })
      ).data
      message(data.message)
      if (data.myboards) {
         setOpen(false)
         setListOfBoards(data.myboards)
         setName('')
      }
   }

   return (
      <Box sx={{ flexGrow: 1 }}>
         {/* Начало диалога по созданию доски */}
         <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Создание доски</DialogTitle>
            <DialogContent>
               <DialogContentText>Для создания доски необходимо указать её название.</DialogContentText>
               <TextField autoFocus margin="dense" id="name" label="Название доски" type="string" fullWidth variant="standard" onChange={handleChange} />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Отмена</Button>
               <Button onClick={handleCreate}>Создать</Button>
            </DialogActions>
         </Dialog>
         {/* Конец диалога по созданию доски */}
         <Typography variant="h2" gutterBottom>
            Мои доски
         </Typography>
         <Box sx={{ my: 2 }}>
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>
               Создать новую доску
            </Button>
         </Box>
         <Suspense fallback={<h2>Загружаем всё необходимое....</h2>}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               {listOfBoards
                  .slice(0)
                  .reverse()
                  .map((board, index) => (
                     <Grid item xs={2} sm={4} md={4} key={index}>
                        <BoardCard name={board.name} boardId={board.boardId} setList={setListOfBoards} />
                     </Grid>
                  ))}
            </Grid>
         </Suspense>
      </Box>
   )
}
