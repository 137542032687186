import React, { useContext, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import { AuthContext } from '../context/AuthContext'
import { useMessage } from '../hooks/message.hook'
import $api from '../http'

const ITEM_HEIGHT = 48

export default function LongMenu(props) {
   const auth = useContext(AuthContext) //Контекст
   const message = useMessage()
   const [anchorEl, setAnchorEl] = React.useState(null)
   const open = Boolean(anchorEl)
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
   }
   const handleClose = () => {
      setAnchorEl(null)
   }
   const useDelete = async () => {
      //Удаление доски
      setAnchorEl(null)
      const data = (
         await $api.post('/api/manager/delete', {
            id: auth.userId,
            name: props.name,
         })
      ).data
      message(data.message)
      props.setList(data.myboards)
   }

   return (
      <div>
         <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon />
         </IconButton>
         <Menu
            id="long-menu"
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
               style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
               },
            }}>
            {/* <MenuItem onClick={handleClose}>
               <Button>Переименовать</Button>
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose}>
               <Button>{props.name}</Button>
            </MenuItem> */}
            <MenuItem onClick={useDelete}>
               <Button color="error">Удалить</Button>
            </MenuItem>
         </Menu>
      </div>
   )
}
