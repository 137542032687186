import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import BoardMenu from './BoardMenu.js'
import Grid from '@mui/material/Unstable_Grid2'
import myImage from '../back2.png'
var link = '/board' //Ссылки вписаны вручную временно  

export default function BoardCard(props) {
   return (
      <Card sx={{ maxWidth: 345 }}>
         <CardActionArea href={'/board/' + props.boardId}>
            <CardMedia component="img"  image={myImage} alt="Картинка для данной доски" />
         </CardActionArea>
         <CardContent>
            <Grid container spacing={2}>
               <Grid xs={10}>
                  {/* <CardActionArea href={link}> */}
                  <CardActionArea href={'/board/' + props.boardId}>
                     <Typography gutterBottom variant="h5" component="div">
                        {props.name}
                     </Typography>
                  </CardActionArea>
               </Grid>
               <Grid xs={2}>
                  <BoardMenu name={props.name} setList={props.setList} />
               </Grid>
            </Grid>
            {/* <Typography variant="body2" color="text.secondary">
               Здесь какое-то описание
            </Typography> */}
         </CardContent>
      </Card>
   )
}
