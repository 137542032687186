import React, { useContext, useEffect, useState } from 'react'
import { useMessage } from '../hooks/message.hook'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'
import Cookies from 'js-cookie'
import myImage from '../logoNavbar.png'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <Link color="inherit" href="#">
            InterBoard
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

const defaultTheme = createTheme()

export const RegistrationPage = () => {
   useTitle('Регистрация - InterBoard')
   const auth = useContext(AuthContext)
   const message = useMessage()
   const [form, setForm] = useState({ email: '', password: '', code: '' })
   const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }
   const registerHandler = async () => {
      try {
         const regData = (await $api.post('/api/auth/register', { ...form })).data
         message(regData.message)
         if (regData.email) {
            const loginData = (await $api.post('/api/auth/login', { email: regData.email, password: regData.password })).data
            message(loginData.message)
            if (Cookies.get('accessToken')) auth.login(Cookies.get('accessToken'), loginData.userId, loginData.name, loginData.prefBoardTheme)
         }
      } catch (e) {}
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }

   return (
      <ThemeProvider theme={defaultTheme}>
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
               sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}>
               <img src={myImage} alt="Описание изображения" style={{ height: '128px' }} />
               <Typography component="h1" variant="h5">
                  Регистрация
               </Typography>
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           autoComplete="given-name"
                           name="firstName"
                           required
                           fullWidth
                           id="firstName"
                           label="Имя"
                           autoFocus
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           required
                           fullWidth
                           id="lastName"
                           label="Фамилия"
                           name="lastName"
                           autoComplete="family-name"
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           required
                           fullWidth
                           id="email"
                           label="Email адрес"
                           name="email"
                           autoComplete="email"
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           required
                           fullWidth
                           name="password"
                           label="Пароль"
                           type="password"
                           id="password"
                           autoComplete="new-password"
                           onChange={changeHandler}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           InputProps={{
                              style: {
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              },
                           }}
                           autoComplete="given-name"
                           name="code"
                           required
                           fullWidth
                           id="code"
                           label="Кодовое слово"
                           onChange={changeHandler}
                        />
                     </Grid>
                     {/* <Grid item xs={12}>
                        <FormControlLabel
                           control={<Checkbox value="allowExtraEmails" color="primary" />}
                           label="I want to receive inspiration, marketing promotions and updates via email."
                        />
                     </Grid> */}
                  </Grid>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={registerHandler}>
                     Зарегистрироваться
                  </Button>
                  <Grid container justifyContent="flex-end">
                     <Grid item>
                        <Link href="/login" variant="body2">
                           Уже есть аккаунт? Авторизируйтесь
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
         </Container>
      </ThemeProvider>
   )
}
