import React, { useContext, useState, useEffect } from 'react'
import { useMessage } from '../hooks/message.hook'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Cookies from 'js-cookie'
const defaultTheme = createTheme()

export const SettingsPage = () => {
   useTitle('Настройки - InterBoard')
   const message = useMessage()
   const auth = useContext(AuthContext) //Контекст
   const saveHandler = async () => {
      try {
         // const data = (await $api.post('/api/manager/saveSettings', { id: auth.userId, prefBoardTheme: theme })).data
         // message(data.message)
         Cookies.set('boardTheme', theme, { expires: 500 })
         message('Настройки сохранены')
      } catch (e) {}
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }
   const [theme, setTheme] = useState('')
   const handleChange = (e) => setTheme(e.target.value)
   useEffect(() => {
      setTheme(Cookies.get('boardTheme'))
   }, [])
   return (
      <ThemeProvider theme={defaultTheme}>
         <Box sx={{ width: '100%', maxWidth: 2000 }}>
            <Container component="main" maxWidth="xs">
               <CssBaseline />
               <Box
                  onSubmit={handleSubmit}
                  sx={{
                     marginTop: 8,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                  }}>
                  <Typography component="h1" variant="h2">
                     Настройки
                  </Typography>
                  <Typography component="h1" variant="h5">
                     Предпочитаемая тема доски
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                     <Select labelId="theme" id="theme" value={theme || 'light'} onChange={handleChange} autoWidth inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={'light'}>Светлая</MenuItem>
                        <MenuItem value={'dark'}>Тёмная</MenuItem>
                     </Select>
                  </FormControl>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={saveHandler}>
                     Сохранить
                  </Button>
               </Box>
            </Container>
         </Box>
      </ThemeProvider>
   )
}
