import React from "react"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTitle from '../hooks/title.hook'

export const ProfilePage = () =>{
   useTitle('Профиль - InterBoard')
    return(
        <Box sx={{ width: '100%', maxWidth: 1000 }}>
         <Typography variant="h1" gutterBottom>
            Профиль
         </Typography>
         <Typography variant="h3" gutterBottom>
            Настройки профиля: смена аватара, смена пароля, управление подпиской и прочее.
         </Typography>
      </Box>
    )

}