import React, { useContext, useEffect, useState } from 'react'
import { useMessage } from '../hooks/message.hook'
import { AuthContext } from '../context/AuthContext'
import $api from '../http'
import useTitle from '../hooks/title.hook'
import Cookies from 'js-cookie'
import myImage from '../logoNavbar.png'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

function Copyright(props) {
   return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
         {'Copyright © '}
         <Link color="inherit" href="#">
            InterBoard
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   )
}

const defaultTheme = createTheme() //видимо тема хз

export const LoginPage = () => {
   useTitle('Авторизация - InterBoard')
   const auth = useContext(AuthContext)
   const message = useMessage()
   const [form, setForm] = useState({ email: '', password: '' })
   const changeHandler = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }
   const loginHandler = async () => {
      try {
         const data = (await $api.post('/api/auth/login', { ...form })).data
         message(data.message)
         if (data.userId) auth.login(Cookies.get('accessToken'), data.userId, data.name, data.prefBoardTheme)
      } catch (e) {}
   }
   const handleSubmit = (event) => {
      event.preventDefault()
   }

   return (
      <ThemeProvider theme={defaultTheme}>
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
               sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}>
               <img src={myImage} alt="Описание изображения" style={{ height: '128px' }} />
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                     InputProps={{
                        style: {
                           paddingLeft: 10,
                           paddingRight: 10,
                        },
                     }}
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email адрес"
                     name="email"
                     autoComplete="email"
                     autoFocus
                     onChange={changeHandler}
                  />
                  <TextField
                     InputProps={{
                        style: {
                           paddingLeft: 10,
                           paddingRight: 10,
                        },
                     }}
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Пароль"
                     type="password"
                     id="password"
                     autoComplete="current-password"
                     onChange={changeHandler}
                  />
                  {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Запомнить меня" /> */}
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={loginHandler}>
                     Войти
                  </Button>
                  <Grid container>
                     {/* <Grid item xs>
                        <Link href="#" variant="body2">
                           Забыли пароль?
                        </Link>
                     </Grid> */}
                     <Grid item>
                        <Link href="/registration" variant="body2">
                           {'Нет аккаунта? Зарегистрируйтесь'}
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
         </Container>
      </ThemeProvider>
   )
}
