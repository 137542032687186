import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { SettingsPage } from './pages/SettingsPage'
import { LoginPage } from './pages/LoginPage'
import { RegistrationPage } from './pages/RegistrationPage'
import { GuidePage } from './pages/GuidePage'
import { NewsPage } from './pages/NewsPage'
import MainPage from './pages/MainPage'
import { ProfilePage } from './pages/ProfilePage'
import 'materialize-css'

export const useRoutes = (isAuthenticated) => {
   if (isAuthenticated) {
      return (
         <Routes>
            <Route
               path="*"
               element={
                  <div className="container">
                     <MainPage />
                  </div>
               }
            />
            <Route
               path="/main"
               exact
               element={
                  <div className="container">
                     <MainPage />
                  </div>
               }
            />
            <Route
               path="/settings"
               exact
               element={
                  <div className="container">
                     <SettingsPage />
                  </div>
               }
            />
            <Route
               path="/guide"
               element={
                  <div className="container">
                     <GuidePage />
                  </div>
               }
            />
            <Route
               path="/news"
               element={
                  <div className="container">
                     <NewsPage />
                  </div>
               }
            />
            <Route
               path="/profile"
               element={
                  <div className="container">
                     <ProfilePage />
                  </div>
               }
            />
            <Route path="/board/:id" element={<Board />} />
         </Routes>
      )
   }
   return (
      <Routes>
         <Route path="*" element={<LoginPage />} />
         <Route path="/" exact element={<LoginPage />} />
         <Route path="/login" exact element={<LoginPage />} />
         <Route path="/registration" exact element={<RegistrationPage />} />
      </Routes>
   )
}
const Board = () => {
   const { id } = useParams()
   //Здесь нужно поменять на interboard.online
   window.location.href = process.env.REACT_APP_API_URL + '/board/' + id
   return null
}
